// Import Libraries
import React from "react";

// Import Styles
import styles from "./ColocationPage.module.scss";

// Import Components
import HeroComponent from "../../Layout/Components/HeroComponent/HeroComponent";
import ServerPageIntro from "../../Components/ServerPageIntro/ServerPageIntro";

// Import Animations
import FadeInOnScroll from "../../util/Animations/FadeInOnScroll";

// import Assets
import ColocationImage from "../../Assets/colocationImage.png";
import ColocationQuoteForm from "./Components/ColocationQuoteForm/ColocationQuoteForm";
import ColocationPricing from "./Components/ColocationPricing/ColocationPricing";

import VRImage from "../../Components/VRImage/VRImage";
import ColocationInfo from "./Components/ColcoationInfo/ColocationInfo";

const ColocationPage = () => {
  return (
    <>
      <HeroComponent
        title="Colocation Services"
        subtitle="Secure, Reliable, And Scalable <br /> Colocation Services"
        buttonText1="Shop Servers"
        buttonLink1="#shopcoloservers"
        showButton1={true}
        buttonText2="Get A Quote"
        buttonLink2="#getcoloquote"
        showButton2={true}
      />

      <ColocationPricing />
      <ColocationInfo />

      {/* Standard Dedicated Servers Section */}
      <FadeInOnScroll>
        {/* <ServerPageIntro
          header="Enterprise-Grade <br/> Colocation Services"
          subHeader="Colocation Solutions"
          text="WebNX provides secure, reliable, and scalable colocation services in world-class data centers, ensuring optimal performance and uptime for your mission-critical operations."
          image={ColocationImage}
          showButton={false}
          buttonText="Learn More"
          buttonLink="/colocation-services"
        /> */}
      </FadeInOnScroll>

      {/* <VRImage /> */}
    </>
  );
};

export default ColocationPage;
