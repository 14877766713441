import React from "react";
import { motion } from "framer-motion";
import styles from "./WhyChooseWebNX.module.scss";
// import Wifi from "../../../../Assets/icons/Wifi-Blue.svg";
import Wifi from "../../../../Assets/icons/Wifi-Icon-Plain.svg";
// import BellIcon from "../../../../Assets/icons/Bell-Blue.svg";
import BellIcon from "../../../../Assets/icons/Bell-Icon-Plain.svg";
// import ShieldIcon from "../../../../Assets/icons/Shield-Blue.svg";
import ShieldIcon from "../../../../Assets/icons/Shield-Icon-Plain.svg";
// import BandwidthIcon from "../../../../Assets/icons/Bandwidth-Blue.svg";
import BandwidthIcon from "../../../../Assets/icons/Bandwidth-Icon-Plain.svg";
// import NetworkIcon from "../../../../Assets/icons/Network-Blue.svg";
import NetworkIcon from "../../../../Assets/icons/Hardware-Icon-Plain.svg";
// import PowerIcon from "../../../../Assets/icons/Power-Blue.svg";
import PowerIcon from "../../../../Assets/icons/SLA-Icon-Plain.svg";

// Define the hover animation
const cardHover = {
  hover: {
    scale: 1.02,
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
    transition: { duration: 0.3 },
  },
};

const WhyChooseWebNX = () => {
  return (
    <section className={styles.container}>
      <h2 className={styles.header}>Why Choose WebNX?</h2>
      <h3 className={styles.subHeader}>The WebNX Advantage</h3>
      <p className={styles.text}>
        At WebNX, we pride ourselves on offering Enterprise Internet Infrastructure Solutions at affordable prices. We combine top-quality components from the top manufacturers with our 100% self-operated data centers and our Route Optimized Networks to bring you IT values unlike anyone else. With over 15 years of industry experience, your enterprise is in good hands with WebNX Internet Services.
      </p>

      <div className={styles.groupContainerBig}>
        {[
          {
            icon: Wifi,
            title: "100% Network SLA",
            description:
              "Guaranteed uptime for network connectivity, ensuring no interruptions in service.",
          },
          {
            icon: PowerIcon,
            title: "100% Power SLA",
            description:
              "Uninterrupted power supply backed by redundant systems to maintain continuous operations.",
          },
          {
            icon: BellIcon,
            title: "24x7x365 Technical Support",
            description:
              "Always available expert support to assist with any technical issues, at any time of day or year.",
          },
          {
            icon: BandwidthIcon,
            title: "Tier-1 Bandwidth Providers",
            description:
              "High-performance, reliable internet connectivity through partnerships with top-tier bandwidth providers.",
          },
          {
            icon: ShieldIcon,
            title: "Self-Operated Data Centers",
            description:
              "Fully owned and managed data centers for complete control over infrastructure and services.",
          },
          {
            icon: NetworkIcon,
            title: "Flexible Hardware Configurations",
            description:
              "Customizable server and hardware setups to meet specific business needs and scalability requirements.",
          },
        ].map(({ icon, title, description }, index) => (
          <motion.article
            key={index}
            className={`${styles.leftGroup} ${styles[`item${index + 1}`]}`}
            variants={cardHover}
            whileHover="hover"
            tabIndex={0} // Make the cards focusable
            aria-label={title} // Improve accessibility for screen readers
          >
            <img className={styles.icon} src={icon} alt={title} />
            <div>
              <h4 className={styles.groupHeader}>{title}</h4> {/* Changed to h4 for SEO */}
              <p className={styles.groupText}>{description}</p>
            </div>
          </motion.article>
        ))}
      </div>
    </section>
  );
};

export default WhyChooseWebNX;
