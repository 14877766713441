import React from 'react';
import styles from '../../FilterComponent.module.scss';

const ProductDisplayComponent = ({ product }) => {
  return (
    <div className={styles.flexRow}>
      <div className={styles.flexCell}>
        {(() => {
          const processor = product.customdata.processor || "";
          const parenIndex = processor.indexOf("(");
          if (parenIndex > -1) {
            const namePart = processor.substring(0, parenIndex).trim();
            const detailsPart = processor.substring(parenIndex).trim();
            return (
              <>
                {namePart}
                <br />
                {detailsPart}
              </>
            );
          }
          return processor;
        })()}
      </div>

      <div className={styles.flexCell}>{product.customdata.memory}</div>
      <div className={styles.flexCell}>{product.customdata.storage}</div>
      <div className={styles.flexCell}>{product.customdata.network}</div>
      <div className={styles.flexCell}>{product.customdata.ips}</div>
      <div className={styles.flexCell}>{product.customdata.gpu}</div>
      <div className={styles.flexCell}>
        <div className={styles.priceContainer}>
          <a
            href={product.product_url}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.priceLink}
          >
            ${product.pricing.USD.monthly}
          </a>
          <a
            className={styles.orderNowButton}
            href={product.product_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Order Now
          </a>
        </div>
      </div>
    </div>
  )
}

export default ProductDisplayComponent