import React, { useState } from "react";
import { motion } from "framer-motion";
import styles from "./ColocationPricing.module.scss";
import FadeInOnScroll from "../../../../util/Animations/FadeInOnScroll";
import ColocationQuoteForm from "../ColocationQuoteForm/ColocationQuoteForm";

const colocationDataOgden = [
  {
    title: "1U /2U Server",
    price: "$45 /m",
    buttonText: "Order Now",
    includes: [
      "1 Amp 208V Power",
      "1Gbps Unmetered (10G Available)",
      "IPv6 Available",
      "/29 (5 Usable IP's)",
      "Datacenter - Ogden, Utah",
    ],
    description: "1U-2U Of Rack Space",
    link: "https://clients.webnx.com/index.php?rp=/store/colocation-ogden-utah/colo-ogd-1u-colocation",
  },
  // {
  //   title: "2U Server",
  //   price: "$59 /m",
  //   buttonText: "Order Now",
  //   includes: [
  //     "1 Amp 208V Power",
  //     "1Gbps Unmetered (10G Available)",
  //     "IPv6 Available",
  //     "/29 (5 Usable IP's)",
  //     "Datacenter - Ogden, Utah",
  //   ],
  //   description: "2U Of Rack Space",
  //   link: "https://clients.webnx.com/index.php?rp=/store/colocation-ogden-utah/colo-ogd-2u-colocation",
  // },
  {
    title: "4U Server",
    price: "$79 /m",
    buttonText: "Order Now",
    includes: [
      "2 Amp (4A 120v / 416W) Power",
      "1Gbps Unmetered (10G Available)",
      "IPv6 Available",
      "/29 (5 Usable IP's)",
      "Datacenter - Ogden, Utah",
    ],
    description: "4U Of Rack Space",
    link: "https://clients.webnx.com/index.php?rp=/store/colocation-ogden-utah/colo-ogd-4u-colocation",
  },
  // {
  //   title: "1/4 Rack",
  //   price: "$199 /m",
  //   buttonText: "Order Now",
  //   includes: [
  //     "2 Amp 208V Power",
  //     "1Gbps Unmetered (10G Available)",
  //     "IPv6 Available",
  //     "/29 (5 Usable IP's)",
  //     "Datacenter - Ogden, Utah",
  //   ],
  //   description: "10U Of Rack Space",
  //   link: "https://clients.webnx.com/index.php?rp=/store/colocation-ogden-utah/colo-ogd-14-rack-colocation",
  // },
  {
    title: "1/2 Rack",
    price: "$499 /m",
    buttonText: "Order Now",
    includes: [
      "10 Amp (≈20A 120V / 2kW Usable Power)",
      "1Gbps Unmetered (10G Available)",
      "IPv6 Available",
      "/29 (5 Usable IP's)",
      "Datacenter - Ogden, Utah",
    ],
    description: "21U Of Rack Space",
    link: "https://clients.webnx.com/index.php?rp=/store/colocation-ogden-utah/colo-ogd-12-rack-colocation",
  },
  {
    title: "Full Rack",
    price: "$899 /m",
    buttonText: "Order Now",
    includes: [
      "30A A+B + Free PDUs 5KW Usable Power",
      "10G Port Full 10G Unmetered",
      "IPv6 Available",
      "/28 IPv4 (13 Usable IP's)",
      "Datacenter - Ogden, Utah",
    ],
    description: "42U Of Rack Space",
    link: "https://clients.webnx.com/index.php?rp=/store/colocation-ogden-utah/colo-ogd-full-rack-colocation",
  },
];

const colocationDataLosAngeles = [
  {
    title: "1U / 2U Server",
    price: "$119 /m",
    buttonText: "Order Now",
    includes: [
      "1 Amp 208V Power",
      "10G port 50TB BW",
      "IPv6 Available",
      "/29 (5 Usable IP's)",
      "Datacenter - Los Angeles, CA",
    ],
    description: "1U-2U Of Rack Space",
    link: "https://clients.webnx.com/index.php?rp=/store/colocation-los-angeles/colo-la-1u-colocation",
  },
  // {
  //   title: "2U Server",
  //   price: "$139 /m",
  //   buttonText: "Order Now",
  //   includes: [
  //     "1 Amp 208V Power",
  //     "1Gbps Unmetered (10G Available)",
  //     "IPv6 Available",
  //     "/29 (5 Usable IP's)",
  //     "Datacenter - Los Angeles, CA",
  //   ],
  //   description: "2U Of Rack Space",
  //   link: "https://clients.webnx.com/index.php?rp=/store/colocation-los-angeles/colo-la-2u-colocation",
  // },
  {
    title: "4U Server",
    price: "$199 /m",
    buttonText: "Order Now",
    includes: [
      "2 Amp (4A 120v / 416W) Power",
      "10G port 50TB BW",
      "IPv6 Available",
      "/29 (5 Usable IP's)",
      "Datacenter - Los Angeles, CA",
    ],
    description: "4U Of Rack Space",
    link: "https://clients.webnx.com/index.php?rp=/store/colocation-los-angeles/colo-la-4u-colocation",
  },
  // {
  //   title: "1/4 Rack",
  //   price: "$600 /m",
  //   buttonText: "Order Now",
  //   includes: [
  //     "2 Amp 208V Power",
  //     "1Gbps Unmetered (10G Available)",
  //     "IPv6 Available",
  //     "/29 (5 Usable IP's)",
  //     "Datacenter - Los Angeles, CA",
  //   ],
  //   description: "10U Of Rack Space",
  //   link: "https://clients.webnx.com/index.php?rp=/store/colocation-los-angeles/colo-la-14-rack-colocation",
  // },
  {
    title: "1/2 Rack",
    price: "$899 /m",
    buttonText: "Order Now",
    includes: [
      "5 Amp 208V (≈10A 120V / 1kW Usable Power)",
      "1Gbps Unmetered (10G Available)",
      "IPv6 Available",
      "/29 (5 Usable IP's)",
      "Datacenter - Los Angeles, CA",
    ],
    description: "21U Of Rack Space",
    link: "https://clients.webnx.com/index.php?rp=/store/colocation-los-angeles/colo-la-12-rack-colocation",
  },
  {
    title: "Full Rack",
    price: "$1500 /m",
    buttonText: "Order Now",
    includes: [
      "30A A+B + Free PDUs 5KW Usable Power",
      "10G Port Full 10G Unmetered",
      "IPv6 Available",
      "/28 IPv4 (13 Usable IP's)",
      "Datacenter - Los Angeles, CA",
    ],
    description: "42U Of Rack Space",
    link: "https://clients.webnx.com/index.php?rp=/store/colocation-los-angeles/colo-la-full-rack-colocation",
  },
];

const ColocationPricing = () => {
  const [showForm, setShowForm] = useState(false);
  const [locationSelected, setLocationSelected] = useState("Ogden");
  

  const locationData = {
    Ogden: colocationDataOgden,
    LosAngeles: colocationDataLosAngeles,
  };

  const handleShowForm = () => {
    setShowForm(!showForm);
  };

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <h2 className={styles.header} id="shopcoloservers">
          Colocation Server Options
        </h2>
        {/* <FadeInOnScroll> */}
          <div className={styles.selectorContainer}>
            <h2
              onClick={() => setLocationSelected("Ogden")}
              className={`${styles.ogden} ${
                locationSelected === "Ogden" ? styles.active : ""
              }`}
            >
              Ogden, Utah
            </h2>
            <h2
              onClick={() => setLocationSelected("LosAngeles")}
              className={`${styles.losangeles} ${
                locationSelected === "LosAngeles" ? styles.active : ""
              }`}
            >
              Los Angeles, CA
            </h2>
          </div>
          <div className={`cardGrid ${styles.cardGrid}`}>
            {locationData[locationSelected].map((item, index) => (
              <div
                className={`normalSpacing ${styles.solutionsCardContainer}`}
                key={index}
                id={
                  index === colocationDataOgden.length - 1
                    ? "getcoloquote"
                    : null
                } // Attach id to the last child
              >
                <div className={`${styles.solutionsCard}`}>
                  <div className={styles.solutionsCardTopRow}>
                    <h3 className={styles.solutionsCardTitle}>{item.title}</h3>
                    <p className={styles.solutionsCardInstantDeploy}>
                      {item.description}
                    </p>
                  </div>
                  <h4 className={styles.solutionsCardPrice}>{item.price}</h4>
                  <hr />
                  <a
                    className={styles.orderNow}
                    href={item.link}
                    aria-label={`Order ${item.title}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.buttonText}
                  </a>
                  <div className={styles.solutionsCardBottomRow}>
                    <div>
                      <p className={styles.solutionsCardIncludes}>Includes:</p>
                      <ul className={styles.solutionsList}>
                        {item.includes.map((include, i) => (
                          <li key={i}>{include}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Custom Quote Section */}
          <div className={styles.customQuoteContainer}>
            <h2 className={styles.customHeader}>Custom Colocation Quote</h2>
            <p className={styles.customColocationText}>
              Submit your request today by filling out our quote request form
              with your specific colocation requirements, and our team will
              promptly get back to you with a personalized solution tailored to
              meet your needs.
            </p>
            <button
              onClick={handleShowForm}
              className={styles.getCustomOrderButton}
            >
              Get Custom Colocation Quote Now!
            </button>
            {showForm && (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.5 }}
              >
                <ColocationQuoteForm />
              </motion.div>
            )}
          </div>
        {/* </FadeInOnScroll> */}
      </div>
    </div>
  );
};

export default ColocationPricing;
