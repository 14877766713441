// Import Libraries
import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';

// Import Components
import HeroComponent from "../../Layout/Components/HeroComponent/HeroComponent";
import FilterComponent from "../../Components/FilterComponent/FilterComponent";
import CustomServerStandardsComponent from "./Components/CustomServerStandardsComponent/CustomServerStandardsComponent";
import DedicatedServerLocations from "./Components/DedicatedServerLocations/DedicatedServerLocations";
import TestNetworkSpeedsComponent from "./Components/TestNetworkSpeedsComponent/TestNetworkSpeedsComponent";
import ServerPageIntro from "../../Components/ServerPageIntro/ServerPageIntro";

// Import Assets
import StandardServer from "../../Assets/Standard_Server.png";
import ServerStandardsComponent from "../../Components/ServerStandardsComponent/ServerStandardsComponent";

// Import Animation
import FadeInOnScroll from "../../util/Animations/FadeInOnScroll";

const DedicatedServersPage = () => {
  const filterRef = useRef(null); // Create a ref for FilterComponent
  const location = useLocation(); // Get the current location

   // Scroll to the FilterComponent if the hash matches
   useEffect(() => {
    if (location.hash === "#filterComponent" && filterRef.current) {
      filterRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);
  return (
    <div>
      <Helmet>
        <title>Standard Dedicated Servers | High-Performance Dedicated Server Hosting</title>
        <meta
          name="description"
          content="Discover WebNX's high-performance dedicated servers. Offering custom-built configurations, 24/7 support, and optimized networks tailored for your needs."
        />
        <meta
          name="keywords"
          content="dedicated servers, custom server hosting, WebNX hosting, high-performance servers, server colocation"
        />
        <meta
          property="og:title"
          content="WebNX | High-Performance Dedicated Servers"
        />
        <meta
          property="og:description"
          content="Explore WebNX's dedicated server solutions, including GPU-enabled servers, custom configurations, and enterprise-grade hosting."
        />
        <meta
          property="og:url"
          content="https://webnx.com/dedicated-servers"
        />
        <meta
          property="og:image"
          content="https://webnx.com/assets/dedicated-servers.jpg"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webnx.com/dedicated-servers" />
      </Helmet>
      
      <HeroComponent
        title="Dedicated Servers"
        subtitle="Solutions For Every Need <br /> Unleash the Power of <br /> WebNX"
        buttonText1="Shop Servers Now"
        buttonLink1="#filterComponent"
        showButton1={true}
        buttonText2="More Information"
        buttonLink2="#moreInfo"
        showButton2={true}
      />

      <div id="filterComponent" ref={filterRef}>
        <FilterComponent defaultFilters={{ "Server Type": ["Standard"] }} />
      </div>

      <FadeInOnScroll>
        <ServerPageIntro
          header="WebNX <br /> Dedicated Servers"
          subHeader="Dedicated Servers"
          text="Hardware changes fast in the dedicated server hosting market. You
            have to act fast to keep up with the changes. No matter what
            configuration you are looking for, we can get you online. WebNX
            offers a wide-range of custom Dedicated Servers. We custom build
            configurations to suit any clients needs. From personal websites to
            large businesses, we have something for everyone. We offer single
            and dual cpu servers. We even have quad cpu servers for those that
            need the extra power. Looking for a server with a video card? No
            problem! WebNX can provide the power for your applications. Whether
            you need CentOS, Debian, Ubuntu Server, or Windows Server - WebNX is
            here for you. Why WebNX? <br /><br /> At WebNX, we test and benchmark hundreds of
            server configurations and parts each year. This allows us to deliver
            high quality, stable, dedicated servers at affordable prices. With
            parts from SuperMicro, Intel, AMD, Tyan, Western Digital, 3Ware, LSI
            and more you can rest assured that you are getting the best bang for
            your buck. Combine that with our High-Performance BGP Route
            Optimized networks and an outstandin  SLA and you have the WebNX
            Advantage. See why more people are switching to WebNX. Signup Today!"
          image={StandardServer}
          hideImageOnMobile={true}
        />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <CustomServerStandardsComponent />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <DedicatedServerLocations />
      </FadeInOnScroll>

      <FadeInOnScroll>
        <TestNetworkSpeedsComponent />
      </FadeInOnScroll>

      {/* Uncomment if needed
      <FadeInOnScroll>
        <ServerStandardsComponent />
      </FadeInOnScroll>
      */}
    </div>
  );
};

export default DedicatedServersPage;
