// Import Libraries
import React from "react";
import { Helmet } from "react-helmet";

// Import SCSS
import styles from "./StorageServerPage.module.scss";

// Import Components
import HeroComponent from "../../Layout/Components/HeroComponent/HeroComponent";
import ServerPageIntro from "../../Components/ServerPageIntro/ServerPageIntro";
import FilterComponent from "../../Components/FilterComponent/FilterComponent";
import CustomServerStandardsComponent from "../DedicatedServersPage/Components/CustomServerStandardsComponent/CustomServerStandardsComponent";
import DedicatedServerLocations from "../DedicatedServersPage/Components/DedicatedServerLocations/DedicatedServerLocations";
import TestNetworkSpeedsComponent from "../DedicatedServersPage/Components/TestNetworkSpeedsComponent/TestNetworkSpeedsComponent";

// Import Assets
import StorageServer from "../../Assets/Storage_Server.png";

// Import Animation
import FadeInOnScroll from "../../util/Animations/FadeInOnScroll";

const StorageServerPage = () => {
  return (
    <div>
      {/* Helmet for Metadata */}
      <Helmet>
        <title>Storage Servers | Scalable Data Solutions by WebNX</title>
        <meta
          name="description"
          content="Explore WebNX storage servers, designed for high-volume data, backups, media, and databases. Reliable, scalable solutions for all storage needs."
        />
        <meta
          name="keywords"
          content="storage servers, scalable storage solutions, NAS servers, SAN servers, data storage servers, WebNX storage"
        />
        <meta property="og:title" content="Storage Servers | Scalable Data Solutions by WebNX" />
        <meta
          property="og:description"
          content="Reliable and scalable storage server solutions by WebNX. Optimize data management with trusted hardware and high-performance networks."
        />
        <meta property="og:image" content="https://webnx.com/assets/storage-server.jpg" />
        <meta property="og:url" content="https://webnx.com/storage-servers" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://webnx.com/storage-servers" />
      </Helmet>

      {/* Hero Section */}
      <HeroComponent
        title="Storage Servers"
        subtitle="Storage Solutions For Every Need <br /> Unleash the Power of <br /> WebNX"
        buttonText1="Shop Servers Now"
        buttonLink1="#filterComponent"
        showButton1={true}
        buttonText2="More Information"
        buttonLink2="#moreInfo"
        showButton2={true}
      />

      {/* Filter Component */}
      <FilterComponent defaultFilters={{ "Server Type": ["Storage"] }} />

      {/* Storage Server Page Intro */}
      <FadeInOnScroll>
        <ServerPageIntro
          header="WebNX <br /> Storage Servers"
          subHeader="Storage Server Solutions"
          text="Data storage needs are growing rapidly, and it's essential to stay ahead with reliable, scalable solutions. At WebNX, we offer a comprehensive range of custom-built Storage Servers designed to handle the demands of any project. From small businesses to large enterprises, our storage servers are optimized to manage high-volume data, backups, media files, and databases with ease. Need enhanced security or specialized configurations? WebNX has you covered with options tailored to your requirements, ensuring your data is accessible, secure, and high-performing. Whether you're looking for NAS, SAN, or dedicated storage, WebNX can deliver the right solution. <br /> <br /> At WebNX, we thoroughly test and benchmark every storage server configuration, delivering stability and performance at competitive prices. Our hardware includes trusted parts from brands like SuperMicro, Intel, AMD, and Western Digital, so you can be confident in the quality and longevity of your storage solution. Coupled with our High-Performance BGP Route Optimized networks and a reliable SLA, WebNX storage solutions provide the foundation for seamless data management. Discover why so many businesses are choosing WebNX for their storage needs - secure your storage server today!"
          image={StorageServer}
          hideImageOnMobile={true}
        />
      </FadeInOnScroll>

      {/* Server Standards */}
      <FadeInOnScroll>
        <CustomServerStandardsComponent />
      </FadeInOnScroll>

      {/* Dedicated Server Locations */}
      <FadeInOnScroll>
        <DedicatedServerLocations />
      </FadeInOnScroll>

      {/* Test Network Speeds */}
      <FadeInOnScroll>
        <TestNetworkSpeedsComponent />
      </FadeInOnScroll>
    </div>
  );
};

export default StorageServerPage;
