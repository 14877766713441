// Import Libraries
import React, { useState } from "react";
import Lottie from "react-lottie";

// Import SCSS
import styles from "./ColocationQuoteForm.module.scss";

// Import Animations
import successAnimationData from ".././../../../Assets/video/Confetti.json";
import FadeInOnScroll from "../../../../util/Animations/FadeInOnScroll";

// Import Components
import CaptchaModalComponent from "../../../../Components/CaptchaModalComponent/CaptchaModalComponent";

// Import ApiLayer
import { sendColocationQuoteForm } from "../../../../ApiLayer/sendColocationOrderForm";

const ColocationQuoteForm = () => {
  const [formData, setFormData] = useState({
    // Contact Information
    name: "",
    email: "",
    phone: "",
    companyName: "",

    // Location Selection
    location: "",

    // Server Details
    numberOfServers: "",
    rackUnits: "",
    powerUsage: "",
    powerRedundancy: "Single",

    // Networking
    bandwidth: "",
    ipAddresses: "",
    networkRedundancy: "Single",

    // Space Requirements
    rackSpace: "Full Rack",
    cabinetType: "Locked Cabinet",
    access: "Remote Only",

    // Additional Services
    remoteHands: "Occasional",
    backupPower: "UPS & Generator",
    monitoring: "Yes",
    dataBackup: "Cloud",
    ddosProtection: "Yes",

    // Special Requirements
    specialRequirements: "",
  });

  const [errors, setErrors] = useState({});
  const [statusMessage, setStatusMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimationData,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
  };

  const dropdownOptions = {
    location: ["Ogden, Utah", "Los Angeles, California"],
    numberOfServers: ["1-5", "6-10", "11-20", "21+"],
    rackUnits: ["1U", "2U", "4U", "Full Rack"],
    powerUsage: ["<500W", "500W-1000W", "1000W-2000W", "2000W+"],
    powerRedundancy: ["Single", "Dual"],
    bandwidth: ["100 Mbps", "1 Gbps", "10 Gbps", "Custom"],
    ipAddresses: ["1", "5", "10", "20", "/29", "/24"],
    networkRedundancy: ["Single Uplink", "Dual Uplink"],
    rackSpace: ["Full Rack", "Half Rack", "Quarter Rack", "Custom"],
    cabinetType: ["Locked Cabinet", "Open Rack"],
    access: ["Remote Only", "Occasional Physical Access", "Frequent Access"],
    remoteHands: ["None", "Occasional", "24/7"],
    backupPower: ["None", "UPS", "UPS & Generator"],
    monitoring: ["No", "Yes"],
    dataBackup: ["None", "Local", "Cloud"],
    ddosProtection: ["No", "Yes"],
  };

  // Input change handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "gpuEnabled" && value === "No") {
      setFormData((prevData) => ({
        ...prevData,
        gpuCount: "",
        gpuModel: "",
        gpuMemory: "",
      }));
    }
  };

  // Form validation
  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
      isValid = false;
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleCaptchaVerification = async (token) => {
    setShowCaptchaModal(false);
    try {
      const response = await sendColocationQuoteForm(formData, token);
      if (response.success) {
        setStatusMessage(response.message); // Show success message
        setIsError(false);
        setShowSuccessAnimation(true); // Trigger success animation
      } else {
        setIsError(true);
        setStatusMessage(response.message); // Show error message
      }
    } catch (error) {
      setIsError(true);
      setStatusMessage("An error occurred. Please try again.");
    }

    setFormData({
      name: "",
      email: "",
      phone: "",
      companyName: "",
      location: "",
      numberOfServers: "",
      rackUnits: "",
      powerUsage: "",
      powerRedundancy: "Single",
      bandwidth: "",
      ipAddresses: "",
      networkRedundancy: "Single",
      rackSpace: "Full Rack",
      cabinetType: "Locked Cabinet",
      access: "Remote Only",
      remoteHands: "Occasional",
      backupPower: "UPS & Generator",
      monitoring: "Yes",
      dataBackup: "Cloud",
      ddosProtection: "Yes",
      specialRequirements: "",
    });
    setErrors({});
    setTimeout(() => setShowSuccessAnimation(false), 5000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setIsError(true);
      setStatusMessage("Please fill out all required fields correctly.");
      return;
    }

    setShowCaptchaModal(true);
  };

  return (
    <>
        <div className={styles.container} id="colocationQuote" >
          <div className={styles.formContainer}>
            <h1 className={styles.header}>Request a Colocation Quote</h1>
            <p className={styles.text}>
              Provide your colocation requirements, and we’ll get back to you
              with a customized quote.
            </p>

            {statusMessage && (
              <p className={isError ? styles.errorText : styles.successText}>
                {statusMessage}
              </p>
            )}
            {showSuccessAnimation && (
              <div className={styles.lottieTopRight}>
                <Lottie
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: successAnimationData,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  height={700}
                  width={700}
                  key={Date.now()} // force re-render by changing key
                />
              </div>
            )}

            <form className={styles.quoteForm} onSubmit={handleSubmit}>
              {/* Contact Information Section */}
              <div className={styles.section}>
                <h3 className={styles.sectionHeader}>Contact Information</h3>
                <div className={styles.grid}>
                  {[
                    {
                      label: "Name",
                      name: "name",
                      type: "text",
                      error: errors.name,
                    },
                    {
                      label: "Email",
                      name: "email",
                      type: "email",
                      error: errors.email,
                    },
                    { label: "Phone", name: "phone", type: "tel" },
                    {
                      label: "Company Name",
                      name: "companyName",
                      type: "text",
                    },
                  ].map((field) => (
                    <div key={field.name} className={styles.formGroup}>
                      <label htmlFor={field.name}>{field.label}</label>
                      <input
                        type={field.type}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        placeholder={field.label}
                      />
                      {field.error && (
                        <span className={styles.errorText}>{field.error}</span>
                      )}
                    </div>
                  ))}
                </div>
              </div>

               {/* Datacenter Location Section */}
            <div className={styles.section}>
              <h3 className={styles.sectionHeader}>Datacenter Location</h3>
              <div className={styles.formGroup}>
                <label htmlFor="location">
                  Select Datacenter Location
                </label>
                <select
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  className={styles.selectInput}
                >
                  <option value="">Select Location</option>
                  <option value="Ogden, Utah">Ogden, Utah</option>
                  <option value="Los Angeles, California">Los Angeles, California</option>
                </select>
              </div>
            </div>


              

              {/* Dropdown Fields */}
              {[
                {
                  sectionTitle: "Server Details",
                  fields: [
                    "numberOfServers",
                    "rackUnits",
                    "powerUsage",
                    "powerRedundancy",
                  ],
                },
                {
                  sectionTitle: "Networking",
                  fields: ["bandwidth", "ipAddresses", "networkRedundancy"],
                },
                {
                  sectionTitle: "Space Requirements",
                  fields: ["rackSpace", "cabinetType", "access"],
                },
                {
                  sectionTitle: "Additional Services",
                  fields: [
                    "remoteHands",
                    "backupPower",
                    "monitoring",
                    "dataBackup",
                    "ddosProtection",
                  ],
                },
              ].map((section) => (
                <div key={section.sectionTitle} className={styles.section}>
                  <h3 className={styles.sectionHeader}>
                    {section.sectionTitle}
                  </h3>
                  <div className={styles.grid}>
                    {section.fields.map((field) => (
                      <div key={field} className={styles.formGroup}>
                        <label htmlFor={field}>
                          {field
                            .replace(/([A-Z])/g, " $1") // Add space before capital letters
                            .replace(/^./, (str) => str.toUpperCase())}{" "}
                          {/* Capitalize the first letter */}
                        </label>
                        <select
                          name={field}
                          value={formData[field]}
                          onChange={handleChange}
                          className={styles.selectInput}
                        >
                          <option value="">
                            Select{" "}
                            {field
                              .replace(/([A-Z])/g, " $1")
                              .replace(/^./, (str) => str.toUpperCase())}
                          </option>
                          {dropdownOptions[field].map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    ))}
                  </div>
                </div>
              ))}

              {/* Special Requirements Section */}
              <div className={styles.section}>
                <h3 className={styles.sectionHeader}>Special Requirements</h3>
                <div className={styles.formGroup}>
                  <textarea
                    name="specialRequirements"
                    value={formData.specialRequirements}
                    onChange={handleChange}
                    placeholder="Enter any additional requirements"
                    rows="4"
                    className={styles.textAreaInput}
                  />
                </div>
              </div>

              <button type="submit" className={styles.sendButton}>
                Submit Quote Request
              </button>
            </form>
          </div>
        </div>
      <CaptchaModalComponent
        isOpen={showCaptchaModal}
        onClose={() => setShowCaptchaModal(false)}
        onVerify={handleCaptchaVerification}
      />
      </>

  );
};

export default ColocationQuoteForm;
