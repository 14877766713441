import React, { useEffect, useState } from "react";
import { getProducts } from "../../ApiLayer/apiLayer";
import styles from "./FilterComponent.module.scss";
import DownIcon from "../../Assets/icons/Down.svg";
import LoaderSpinner from "../../util/LoadingComponent/LoaderSpinner";
import Pagination from "./Components/Pagination/Pagination";
import ProductDisplayComponent from "./Components/ProductDisplayComponent/ProductDisplayComponent";
import ProductDisplayHeaderComponent from "./Components/ProductDisplayHeaderComponent/ProductDisplayHeaderComponent";

const FilterComponent = ({ defaultFilters = {} }) => {
  // State variables
  const [openFilters, setOpenFilters] = useState([]);
  const [productData, setProductData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  

  // Initialize selectedFilters with default values or empty Sets
  const [selectedFilters, setSelectedFilters] = useState({
    Location: new Set(defaultFilters.Location || []),
    "Server Type": new Set(defaultFilters["Server Type"] || []),
    Brand: new Set(defaultFilters.Brand || []),
    Model: new Set(defaultFilters.Model || []),
  });
  const recordsPerPage = 8;

  // const CACHE_KEY = "productDataCache";

  // Filter options
  const filterOptions = {
    Location: ["Ogden, UT", "Manhattan, NYC", "Los Angeles, CA"],
    "Server Type": ["Standard", "GPU Enabled", "Storage"],
    Brand: ["AMD", "INTEL"],
    Model: ["Ryzen", "Threadripper", "Xeon", "EPYC"],
  };

  const locationMapping = {
    "Ogden, UT": "Datacenter - Ogden, Utah",
    "Manhattan, NYC": "Datacenter - Manhattan, New York",
    "Los Angeles, CA": "Datacenter - Los Angeles, California",
  };

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getProducts();

        if (response.products) {
          setProductData(response.products);
          setFilteredData(response.products);
        } else {
          setError("No Products Found");
        }
      } catch (error) {
        console.error("Error:", error);
        setError("Failed to Fetch Product Data");
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Fetch data when component mounts
  }, []);

  // Reapply filters when product data or selected filters change
  useEffect(() => {
    if (productData.length > 0) {
      applyFilters(selectedFilters);
    }
  }, [productData, selectedFilters]);

  // Fetch product data function (used for refetching if needed)
  const fetchProductData = async () => {
    try {
      setLoading(true);
      const response = await getProducts();

      if (response.products) {
        setProductData(response.products);
        setFilteredData(response.products);
      } else {
        setError("No Products Found");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to Fetch Product Data");
    } finally {
      setLoading(false);
    }
  };

  // Handle toggle for filter dropdowns
  const toggleOpen = (index) => {
    setOpenFilters((prevOpenFilters) => {
      const updatedFilters = [...prevOpenFilters];
      updatedFilters[index] = !updatedFilters[index];
      return updatedFilters;
    });
  };

  // Handle filter option selection
  const handleFilterToggle = (filterType, filterValue) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      const filterSet = new Set(updatedFilters[filterType]);

      // Toggle filter selection
      if (filterSet.has(filterValue)) {
        filterSet.delete(filterValue);
      } else {
        filterSet.add(filterValue);
      }

      updatedFilters[filterType] = filterSet;
      applyFilters(updatedFilters);
      return updatedFilters;
    });
  };

  // Apply filters based on selected filters
  const applyFilters = (filters) => {
    let filteredProducts = productData;

    // Filter by location
    if (filters.Location.size > 0) {
      filteredProducts = filteredProducts.filter((product) => {
        const productLocation = product.customdata.location
          ?.toLowerCase()
          .trim();
        return Array.from(filters.Location).some((loc) => {
          const mappedLocation = locationMapping[loc]?.toLowerCase().trim();
          return mappedLocation === productLocation;
        });
      });
    }

    // Filter by brand
    if (filters.Brand.size > 0) {
      filteredProducts = filteredProducts.filter((product) => {
        const productBrand = product.customdata.brandname?.toUpperCase().trim();
        return filters.Brand.has(productBrand);
      });
    }

    // Filter by server type
    if (filters["Server Type"].size > 0) {
      filteredProducts = filteredProducts.filter((product) => {
        const serverType = product.customdata.type?.toLowerCase().trim();
        return Array.from(filters["Server Type"]).some(
          (type) => type.toLowerCase() === serverType
        );
      });
    }

    // Filter by model
    if (filters.Model.size > 0) {
      filteredProducts = filteredProducts.filter((product) => {
        const productModel = product.customdata.model?.toLowerCase().trim();
        return Array.from(filters.Model).some(
          (model) => model.toLowerCase() === productModel
        );
      });
    }

    setFilteredData(filteredProducts);
    setCurrentPage(1); // Reset to the first page after filtering
  };

  // Clear all filters
  const clearAllFilters = () => {
    // Reset all filter categories to empty Sets
    setSelectedFilters({
      Location: new Set(),
      Brand: new Set(),
      "Server Type": new Set(),
      Model: new Set(),
    });
    // Reset the filtered data to the full product data
    setFilteredData(productData);
    setCurrentPage(1);
  };

  // Pagination logic
  const totalPages = Math.ceil(filteredData.length / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  // Render active filters with headers - NEW
  const renderActiveFilters = () => {
    // Iterate over selectedFilters and group by category
    return Object.keys(selectedFilters).map((filterType) => {
      const activeFilters = Array.from(selectedFilters[filterType]);

      // Only render if there are active filters in the category
      if (activeFilters.length > 0) {
        return (
          <div key={filterType} className={styles.activeFilterGroup}>
            <p className={styles.activeFilterHeader}>{filterType}:</p>
            {activeFilters.map((filter, index) => (
              <span
                key={`${filterType}-${index}`}
                className={styles.activeFilter}
              >
                {filter}
              </span>
            ))}
          </div>
        );
      }
      return null;
    });
  };

  return (
    <div className={styles.container} id="filterComponent">
      <div className={styles.col1}>
        <h4>Filters</h4>
        <div className={styles.filterOptionsContainer}>
          {Object.keys(filterOptions).map((filterType, index) => (
            <div key={filterType} className={styles.filterItem}>
              <div
                className={`${styles.filterOptionsItemClosed} ${
                  openFilters[index] ? styles.active : ""
                }`}
                onClick={() => toggleOpen(index)}
              >
                <p>{filterType}</p>
                <img
                  src={DownIcon}
                  alt={`Toggle ${filterType}`}
                  style={{
                    transform: openFilters[index]
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                />
              </div>

              {openFilters[index] && (
                <div
                  className={`${styles.filterOptionsItemOpen} ${
                    openFilters[index] ? styles.expanded : ""
                  }`}
                >
                  <ul className={styles.dropdownList}>
                    {filterOptions[filterType].map((option) => (
                      <li key={option} className={styles.dropdownItem}>
                        <label className={styles.dropdownLabel}>
                          <input
                            type="checkbox"
                            className={styles.checkbox}
                            checked={selectedFilters[filterType].has(option)}
                            onChange={() =>
                              handleFilterToggle(filterType, option)
                            }
                          />
                          {option}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.col2}>
        <p className={styles.activeFiltersTitle}>Active Filters</p>
        <div className={styles.activeFiltersContainer}>
          <div className={styles.activeFilters}>
            {renderActiveFilters()}
            {Object.keys(selectedFilters).every(
              (key) => selectedFilters[key].size === 0
            ) && <p>No active filters</p>}
          </div>
          <p className={styles.clearAll} onClick={clearAllFilters}>
            CLEAR ALL
          </p>
        </div>
        <hr />

        {/* ------------------------------------------------------------------------------------------- CHECKPOIINTTTTTTTTTTTTTTTTT */}

        {/* NEW */}
        <div className={styles.tableContainer}>
          {/* Static Header Row */}
            <ProductDisplayHeaderComponent />

          {/* Loader, Error, or Data Rows */}
          {loading ? (
            <div className={styles.loaderRow}>
              <LoaderSpinner loading={loading} />
            </div>
          ) : error ? (
            <div className={styles.errorRow}>{error}</div>
          ) : currentRecords.length > 0 ? (
            currentRecords.map((product, index) => (
              <React.Fragment key={product.pid}>
                <ProductDisplayComponent key={product.pid} product={product}/>
              </React.Fragment>
            ))
          ) : (
            <div className={styles.noDataRow}>No products available.</div>
          )}
        </div>

        {/* Pagination Controls */}
          <Pagination 
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
      </div>
    </div>
  );
};

export default FilterComponent;
