import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitization
import { motion, useScroll, useTransform } from 'framer-motion';
import styles from '../HeroComponent/HeroComponent.module.scss';

const HeroComponent = ({
  title,
  subtitle,
  buttonText1,
  buttonLink1,
  buttonText2,
  buttonLink2,
  showButton1,
  showButton2,
  customStyles,
}) => {
  // Track scroll position
  const { scrollY } = useScroll();

  // Animate text scale and opacity as you scroll
  const textScale = useTransform(scrollY, [0, 700], [1, 0.8]);
  const textOpacity = useTransform(scrollY, [0, 700], [1, 0]);

  // Animate overlay opacity to darken as you scroll
  const overlayOpacity = useTransform(scrollY, [0, 700], [0, 0.5]);

  // Sanitize the subtitle
  const cleanSubtitle = subtitle ? DOMPurify.sanitize(subtitle) : '';

  return (
    <header className={`${styles.backgroundImage}`} style={customStyles}>
      {/* Dark overlay with opacity effect */}
      <motion.div
        className={styles.overlay}
        style={{ opacity: overlayOpacity }}
      />

      {/* Text with scaling and fading effect */}
      <motion.div
        className={styles.overlayText}
        style={{ scale: textScale, opacity: textOpacity }}
      >
        <div>
          {title && <h1>{title}</h1>}
          {subtitle && (
            <h2
              dangerouslySetInnerHTML={{ __html: cleanSubtitle }}
              className={styles.subtitle}
            />
          )}
        </div>
        <div className={styles.buttonContainer}>
          {showButton1 && (
            <a
              href={buttonLink1}
              className={styles.heroButton}
              aria-label={buttonText1}
            >
              {buttonText1}
            </a>
          )}
          {showButton2 && (
            <a
              href={buttonLink2}
              className={styles.heroButton2}
              aria-label={buttonText2}
            >
              {buttonText2}
            </a>
          )}
        </div>
      </motion.div>
    </header>
  );
};

// PropTypes to enforce prop types and default props
HeroComponent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText1: PropTypes.string,
  buttonLink1: PropTypes.string,
  buttonText2: PropTypes.string,
  buttonLink2: PropTypes.string,
  showButton1: PropTypes.bool,
  showButton2: PropTypes.bool,
  customStyles: PropTypes.object,
};

export default HeroComponent;
