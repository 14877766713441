import React from "react";
import styles from '../../FilterComponent.module.scss';

const ProductDisplayHeaderComponent = () => {
  return (
    <div className={styles.flexHeader}>
      <div className={ `${styles.flexCell} ${styles.darker}`}>Processor</div>
      <div className={styles.flexCell}>Memory</div>
      <div className={styles.flexCell}>Storage</div>
      <div className={styles.flexCell}>Network</div>
      <div className={styles.flexCell}>IP(s)</div>
      <div className={styles.flexCell}>GPU</div>
      <div className={styles.flexCell}>Price / Month</div>
    </div>
  );
};

export default ProductDisplayHeaderComponent;
