import React from 'react';
import styles from './Pagination.module.scss';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    if (totalPages <= 1) return null; // Hide paginatino if only 1 page

    const maxPagesToShow = 3;
    const pageNumbers = [];

    // Always show the first page
    pageNumbers.push(1);

    if (currentPage > maxPagesToShow + 1) {
        pageNumbers.push("...");
    }

    // Show middle pages around the current page
    let startPage = Math.max(2, currentPage - 1);
    let endPage = Math.min(totalPages - 1, currentPage + 1);

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    if (currentPage < totalPages - maxPagesToShow) {
        pageNumbers.push("...");
    }

    // Always show the last page
    pageNumbers.push(totalPages);

  return (
    <div>
        <div className={styles.pagination}>
            {/* Previous Button */}
            <button
                className={styles.pageButton}
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                {"<"}
            </button>

            {/* Page Numbers with Ellipses */}
            {pageNumbers.map((page, index) => (
                <button
                    key={index}
                    onClick={() => typeof page === "number" && onPageChange(page)}
                    className={currentPage === page ? styles.active : ""}
                    disabled={page === "..."}
                >
                    {page}
                </button>
            ))}

            {/* Next Button */}
            <button
                className={styles.pageButton}
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                {">"}
            </button>
        </div>
    </div>
  )
}

export default Pagination